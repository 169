<template>
  <div class="k-9sptci" v-loading="loading">
    <el-form
      :inline="true"
      class="k-9sptci-header"
      :model="searchForm"
      ref="searchForm"
    >
      <el-form-item label="场次" prop="uid">
        <div v-if="session_name != ''">{{ session_name }}</div>
      </el-form-item>
      <div class="add-sect" v-if="type_id != 'qualifier'">
        <el-button
          class="add-btn"
          size="small"
          type="primary"
          @click="saveSect()"
          >新增</el-button
        >
        <!-- <el-button @click="submitSect">提交</el-button> -->
        <popconfirmButton
          :perm="`match:gruop_save`"
          content="是否确认提交？"
          text="提交"
          @onConfirm="submitSect"
        ></popconfirmButton>
      </div>
    </el-form>

    <el-table :data="dataTable" style="margin-top: 20px" border>
      <template v-for="(item, index) in dataList">
        <el-table-column
          :key="index"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </template>

      <el-table-column label="参赛选手" width="300">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.uid"
            placeholder="请选择"
            @change="storeData(scope.row)"
          >
            <el-option
              v-for="item in uniqueMembers(scope.row.members)"
              :key="item.uid"
              :label="item.name"
              :value="item.uid"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <perButton
            :perm="`match:stage_group`"
            @click="changeGroup(scope.row, scope.$index)"
            style="margin-left: 10px"
            >替换门派</perButton
          >
          <el-button
            v-if="type_id != 'qualifier'"
            class="add-btn"
            size="small"
            type="primary"
            @click="delSect(scope.$index)"
            >删除</el-button
          >
        </template>
      </el-table-column>
      <!-- //门派状态 -->
      <el-table-column v-if="type_id === 'qualifier'" label="门派状态">
        <template slot-scope="scope">
          <div v-if="scope.row.sect_status === 0">正常参赛</div>
          <div v-if="scope.row.sect_status === 1">无效:门派弃赛</div>
          <div v-if="scope.row.sect_status === 2">败:门派被取消资格</div>
          <div v-if="scope.row.sect_status === 3">败:门派降为替补</div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 除预选赛外的替换门派 -->
    <change_group
      :match_id="match_id"
      :session_id="session_id"
      :season_name="season_name"
      :stage="stage"
      :from_data="groupData"
      :index="index"
      v-if="groupVisible"
      @changed="onGroupChanged"
      @closed="groupVisible = false"
      @getId="onsetID"
    ></change_group>
    <!-- 预选赛替换门派 -->
    <change_group_qualifier
      :season_id="season_id"
      :match_id="match_id"
      :session_id="session_id"
      :season_name="season_name"
      :stage="stage"
      :from_data="groupData"
      :index="index"
      v-if="groupVisible_qualifier"
      @call-parent="parentFunction"
      @changed="onGroupChanged"
      @closed="groupVisible = false"
      @getId="onsetID"
    ></change_group_qualifier>
    <!-- 设置添加门派id -->
    <el-dialog
      v-loading="loading"
      :visible.sync="patternVisible"
      v-if="patternVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <div>
        <div>
          <el-form ref="patternForm" :model="patternData" label-width="100px">
            <el-form-item
              label="门派id"
              prop="pattern"
              :rules="[
                {
                  required: true,
                  message: '请输入门派id',
                  trigger: ['blur', 'change'],
                },
              ]"
            >
              <el-input
                v-model="patternData.pattern"
                type="text"
                placeholder="可支持批量输入门派id,用;隔开即可"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="savePattern">确定</el-button>
              <el-button @click="patternVisible = false">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import perButton from "@/components/perm/perButton";
import { randomStr } from "@/utils/randomStr";
import change_group from "./components/session/change_group.vue";
import change_group_qualifier from "./components/session/change_group_qualifier.vue";
export default {
  name: "s1_session_group",
  components: {
    perButton,
    change_group,
    change_group_qualifier,
  },
  computed: {
    ...mapState("match", ["match_types"]),
  },
  data() {
    return {
      match_type: "",
      season_id: "",
      num: 0,
      replace_team: {},
      // newGroup: [],
      patternData: {
        pattern: "",
      },
      ids: [],
      type_id: "s1",
      match_id: "",
      season_name: "",
      loading: false,
      stage: "",
      index: "",
      group: "",
      session_name: "",
      session_id: "",
      stage_status: "",
      searchForm: {
        sect_id: "",
        sect_name: "",
      },
      dataTable: [],
      dataList: [
        {
          prop: "sect_id",
          label: "门派ID",
          width: 300,
        },
        {
          prop: "sect_name",
          label: "门派名称",
          width: 200,
        },
      ],
      resultSects: [
        {
          id: 0,
          value: "暂无",
        },
        {
          id: 1,
          value: "无效：门派弃赛",
        },
        {
          id: 2,
          value: "败：门派被取消资格",
        },
        {
          id: 3,
          value: "败：门派降为替补",
        },
      ],
      selectIdx: -1,
      groupVisible: false,
      groupVisible_qualifier: false,
      groupData: {
        from_id: "",
        from_group: "",
        from_sect_id: "",
        from_sect_name: "",
        to_id: "",
      },
      match: false,
      patternVisible: false,
    };
  },
  async activated() {
    this.$store.commit("tabs/setVNode", this.$vnode);
    this.$store.commit("tabs/setVNode", this.$vnode);
    //首先先从本地取
    this.season_id = this.$route.query.season_id_s4;
     this.match_type = this.$route.query.match_type;
        this.type_id = this.$route.query.type_id;
        this.match_id = this.$route.query.match_id;
        this.season_name = this.$route.query.season_name;
        this.stage = this.$route.query.stage;
        this.index = this.$route.query.index;
        this.group = this.$route.query.group;
        this.session_name = this.$route.query.session_name;
        this.session_id = this.$route.query.session_id;
    this.handleQuery();
  },
  methods: {
    uniqueMembers(rows) {
      return this.getFilter(rows);
    },
    //去重
    getFilter(arr) {
      if (arr && arr.length != 0) {
        let rec = [];
        let obj = {};
        arr.forEach((val) => {
          if (!obj[val.uid]) {
            obj[val.uid] = true;
            rec.push(val);
          }
        });
        return rec;
      } else {
        return arr;
      }
    },
    //下拉框
    async selectSect(_id, sect_status) {
      console.log(111111);
      // let saveData = {
      //   _id: _id,
      //   sect_status: sect_status,
      //   match_id: this.match_id,
      // };
      // let res = await this.$http.match.update_sect_status_s2(saveData);
      // if (res.code != 0) {
      //   this.handleQuery();
      // }
    },
    // 换参赛选手
    async storeData(row) {
      //获取本地存储的数据，等于空的时候移除，不是我写的
      localStorage.setItem(this.session_id, JSON.stringify(this.dataTable));
      if (this.type_id === "qualifier" && this.match_type === "match_s4") {
        console.log(row.uid);
        console.log(row.sect_id);
        let res = await this.$http.match.s4_update_session_player({
          match_id: this.match_id,
          session_id: this.session_id,
          sect_id: row.sect_id,
          uid: row.uid,
        });
        if (res.code === 0) {
          this.$message.success('替换参赛选手成功')
        }
      }
    },
    //新增
    saveSect() {
      this.patternVisible = true;
      this.patternData.pattern = "";
    },
    //清除本地缓存中指定的一条
    delSect(index) {
      //本地移除这条数据
      this.dataTable.splice(index, 1);
      localStorage.setItem(this.session_id, JSON.stringify(this.dataTable));
    },
    //新增确定按钮
    savePattern() {
      this.$refs.patternForm.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          let saveData = {
            match_id: this.match_id,
            session_id: this.session_id,
            sect_ids: this.patternData.pattern,
            
          };

          let res = await this.$http.match_subject.new_session_add_group(
            saveData
          );
          this.loading = false;

          if (res.code == 0) {
            this.patternVisible = false;
            this.dataTable = this.dataTable.concat(res.data);
            let resultArr = this.dataTable.filter((obj, index, array) => {
              return index === array.findIndex((item) => item._id === obj._id); // 返回不重复的对象
            });
            if (this.dataTable.length != resultArr.length) {
              this.$message({
                type: "success",
                message: "已过滤掉重复门派id的数据",
              });
              this.dataTable = resultArr;
            } else {
              this.$message({
                type: "success",
                message: "新增成功",
              });
            }
            //添加本地存储
            this.storeData();
          } else {
            this.patternVisible = true;
          }
        }
      });
    },
    // 提交
    async submitSect() {
      const count = this.dataTable.filter(
        (item) => item.sect_status === 0
      ).length;

      if (count > 12) {
        this.$message({
          type: "warning",
          message: "参赛门派不能超过12个",
        });
        return;
      }

      const team_info = this.dataTable.map((item) => ({
        sect_id: item.sect_id,
        uid: item.uid,
        sect_status: item.sect_status,
      }));

      if (team_info.length === 0) {
        this.$message({
          type: "error",
          message: "无数据",
        });
        return;
      }

      const saveData = {
        match_id: this.match_id,
        session_id: this.session_id,
        team_info: team_info,
        replace_team: this.replace_team,
      };

      const res = await this.$http.match_subject.new_session_add_group_league(
        saveData
      );

      if (res.code === 0) {
        this.$message({
          type: "success",
          message: "提交成功",
        });
        localStorage.removeItem(this.session_id);
      }
    },
    changeGroup(row, index) {
      this.groupData = {
        _id: row._id,
        group: row.group,
        sect_id: row.sect_id,
        uid: row.uid,
        sect_status: row.sect_status,
        sect_name: row.sect_name,
        season_name: this.season_name,
        type_id: this.type_id,
      };
      if (this.type_id === "qualifier") {
        this.groupVisible_qualifier = true;
      } else {
        this.groupVisible = true;
      }

      //联赛选择的下标
      this.selectIdx = index;
    },
    //替换
    onGroupChanged(data) {
      this.dataTable.splice(this.selectIdx, 1, data);
      //添加本地存储
      this.storeData();
    },
    // 获取门派信息
    async handleQuery() {
      let res = await this.$http.match_subject.new_stage_group_list_league({
        ...this.searchForm,
        match_id: this.match_id,
        session_id: this.session_id,
        season_id: this.$route.query.season_id,
      });

      this.dataTable = res.data;
    },
    parentFunction() {
      this.handleQuery();
      console.log(
        "🚀 ~ file: session_group_league.vue:452 ~ parentFunction ~ this.dataTable:",
        this.dataTable
      );
    },

    onsetID(data) {
      this.replace_team.push(data);
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  destroyed() {},
};
</script>
<style lang="less" scoped>
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  .add-sect {
    padding-right: 20px;
    .add-btn {
      margin-right: 10px;
    }
  }
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}
.resultLeft {
  margin-left: 20px;
}
</style>
